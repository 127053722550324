import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../pages/Home.vue";
import User from "../pages/User.vue";
import Main from "../pages/Main.vue";
import Login from "../pages/Login.vue";
import Move from "../pages/Moves.vue";
import Cates from "../pages/Cates.vue";
import Setting from "../pages/Setting.vue";
import VipCate from "../pages/VipCate.vue";
import Orders from "../pages/Orders.vue";
import Agent from "../pages/Agent.vue";
import RefAgent from "../pages/RefAgent.vue";
import View from "../pages/view.vue";
import Stars from "../pages/Stars.vue";
import Huifu from "../pages/Huifu.vue";


Vue.use(VueRouter);

const routes_menu = [
  {
    path: "/",
    component: Main,
    redirect: "/home",
    children: [
      { path: "home", name: "home", component: Home },
      { path: "/moves/movelist", name: "movelist", component: Move },
      { path: "cates", name: "cates", component: Cates },
      { path: "setting", name: "setting", component: Setting },
      { path: "vipcate", name: "vipcate", component: VipCate },
      { path: "users", name: "users", component: User },
      { path: "orders", name: "orders", component: Orders },
      { path: "agent", name: "agent", component: Agent },
      { path: "agent_ref", name: "agent_ref", component: RefAgent },
      { path: "view", name: "view", component: View },
      { path: "stars", name: "stars", component: Stars },
      { path: "huifu", name: "huifu", component: Huifu },


    ],
  },
  {
    path: "/login",
    name: "login",
    component: Login,
  },
];

const router = new VueRouter({
  routes: routes_menu, // (缩写) 相当于 routes: routes
});

export default router;
