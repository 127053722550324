import Vue from 'vue'
import App from './App.vue'
import router from './router/index.js'
import { init } from '@/api'

import './assents/css/quill.core.css'
import './assents/css/quill.bubble.css'
import './assents/css/quill.snow.css'
import VueQuillEditor  from 'vue-quill-editor'
// 全局引入global
import global from "./utls/global.js"
Vue.prototype.global = global
Vue.use(VueQuillEditor)

import Antd from 'ant-design-vue';

import 'ant-design-vue/dist/antd.css';

router.beforeEach((to, from, next) => {
  init().then((res)=>{
    localStorage.setItem("user",res.data);
    if (to.name == 'login') {
      next({name:'home'})
    }else{
      next()
    }
    
  }).catch(()=>{
    if (to.name != 'login') {
      next({name:'login'})
    }else{
      next()
    }
    console.log('====================================');
    console.log('aaa');
    console.log('====================================');
})
 
})

Vue.config.productionTip = false
Vue.use(Antd);
new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
