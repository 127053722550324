<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <div>
    <div class="top_header">
      <a-button @click="show_add_body" type="primary">添加</a-button>
    </div>
    <a-table
      :key="user_key"
      :columns="columns"
      :data-source="list"
      :pagination="false"
    >
      

      <a-switch
        slot-scope="text, record"
        slot="status"
        @change="status_change(record)"
        :defaultChecked="text == 1 ? true : false"
        checked-children="开"
        un-checked-children="关"
        default-checked
      />
    </a-table>
    <a-modal
      title="添加"
      :visible="add_visible"
      @ok="add_handleOk"
      @cancel="add_handleCancel"
      destroyOnClose
      :maskClosable="false"
      width="900px"
    >
      <a-form-model :model="add_data">
        <a-form-model-item required label="选择位置">
          <a-select style="width: 120px" @change="po_handleChange">
            <a-select-option
              v-for="(item, index) in positions"
              :key="index"
              :value="item.id"
            >
              {{ item.title }}
            </a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item required label="选择显示分类">
          <a-select style="width: 120px" @change="cate_handleChange">
            <!-- <a-select-option
            @click="vuew_cange(value)"
              v-for="(value, index) in cates.dolat"
              :key="index"
              :value="value.id"
            >
              {{ value.name }}
            </a-select-option> -->
            <a-select-option
            @click="vuew_cange(value)"
              v-for="(value, index) in cates.tur"
              :key="index"
              :value="value.id"
            >
              {{ value.name }}
            </a-select-option>
            <a-select-option
            @click="vuew_cange1(value)"
              v-for="(value, index) in cates.type"
              :key="index"
              :value="value.name"
            >
              {{ value.name }}
            </a-select-option>
          </a-select>
        </a-form-model-item>
      </a-form-model>
    </a-modal>
  </div>
</template>
  <script>
import {
  getViewList,
  getViewItem,
  addViewItem,
  updateViewData,
  deleteViewItem,
  getTypeList,
  getCates,
} from "@/api";
const columns = [
  {
    dataIndex: "id",
    key: "id",
    align: "center",
    title: "id",
  },

  {
    title: "位置",
    align: "center",
    dataIndex: "title",
  },

  {
    title: "显示分类",
    align: "center",
    dataIndex: "type",
  },
  {
    title: "是否显示",
    align: "center",
    dataIndex: "status",
    scopedSlots: { customRender: "status" },
  },
];

export default {
  data() {
    return {
      columns,
      positions: [
        {
          id: 1,
          title: "باش بەت",
        },
        {
          id: 2,
          title: "تىياتىر",
        },
      ],
      list: [],
      user_page: 1,
      user_total: 0,
      user_key: 0,
      search_desc: "",
      add_visible: false,
      cates: [],
      tur: [],
      add_data: {
        title: "",
        type: "",
        view_type: "",
        cate_type: "",
        status: 1,
      },
    };
  },
  methods: {
    vuew_cange(item){
      console.log(item);
      this.add_data.cate_type=2
      this.add_data.type=item.id
    },
    vuew_cange1(item){
      console.log(item);
      this.add_data.cate_type=1
      this.add_data.type=item.id
    },
    cate_handleChange(e) {},
    po_handleChange(e) {
      console.log(e);
      this.add_data.view_type = e;
      if (e == 1) {
        this.add_data.title = "باش بەت";
      } else {
        this.add_data.title = "تىياتىر";
      }
    },
    add_handleCancel() {
      this.add_visible = false;
    },
    add_handleOk() {
      addViewItem(this.add_data).then(res=>{
        console.log(res);
      })
    },
    show_add_body() {
      this.add_visible = true;
    },

    status_change(e) {
      const param = {
        status: e.status == 1 ? 0 : 1,
      };
      updateViewData(param, e.id).then((res) => {
        console.log(res);
      });
    },
  },
  mounted() {
    getViewList(1).then((res) => {
      this.list = res.data.data;
    });
    getCates().then((res) => {
      this.cates = res.data.data;
    });
    getTypeList().then((res) => {
      this.tur = res.data.data;
    });
  },
};
</script>
  <style scoped>
.input_body {
  margin: 0 auto;
}
.serach_body {
  text-align: center;
  align-self: center;
  width: 300px;
  background-color: white;
  display: flex;
  direction: ltr;
  margin: 0 auto;
}
.top_header {
  background-color: #f0f2f5;
  width: 100%;
  height: 80px;
  direction: rtl;
  display: flex;
}
.caozuo {
  display: flex;
}
</style>