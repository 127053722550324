<!-- eslint-disable vue/multi-word-component-names -->
<template>
    <div>
      <!-- <div class="top_header">
        <div class="serach_body">
          <a-input-search
            class="input_body"
            placeholder="输入信息"
            enter-button="搜索"
            @search="onSearch"
          />
        </div>
      </div> -->
      <a-table
        :key="user_key"
        :columns="columns"
        :data-source="user_list"
        :pagination="false"
      >
      <div slot="status" slot-scope="text,record">
        <div v-if="text==0">未支付</div>
        <div v-if="text==1">已支付</div>
      </div>
  
        <a-switch
          slot-scope="text, record"
          slot="is_vip"
          @change="status_change(record)"
          :defaultChecked="text == 1 ? true : false"
          checked-children="开"
          un-checked-children="关"
          default-checked
        />
      </a-table>
      <a-pagination
        @change="user_page_change"
        :total="user_total"
        show-less-items
      />
    </div>
  </template>
  <script>
  import { getAgent, showAgent, setAgent } from "@/api";
  const columns = [
    {
      dataIndex: "id",
      key: "id",
      align: "center",
      title: "id",
    },
  
    {
      title: "用户ID",
      align: "center",
      dataIndex: "user_id",
    },
    
    {
      title: "金额",
      align: "center",
      dataIndex: "price",
    },
    
  ];
  
  export default {
  
    data() {
      return {
        columns,
        user_list: [],
        user_page: 1,
        user_total: 0,
        user_key: 0,
        search_desc: "",
      };
    },
    methods: {
  
      onSearch(value) {
        this.user_page = 1;
        
        getAgent(this.user_page, value).then((res) => {
          this.user_list = res.data.data.data;
          this.user_total = res.data.data.total;
        });
      },
      user_page_change(e) {
        this.user_page = e;
        getAgent(this.user_page).then((res) => {
          this.user_list = res.data.data.data;
          this.user_total = res.data.data.total;
          this.user_key = this.user_key + 1;
        });
      },
      status_change(e) {
        const param = {
          is_vip: e.is_vip == 1 ? 0 : 1,
        };
        setAgent(param, e.id).then((res) => {
          console.log(res);
        });
      },
    },
    mounted() {
        getAgent(this.user_page,'').then((res) => {
        this.user_list = res.data.data.data;
        this.user_total = res.data.data.total;
      });
    },
  };
  </script>
  <style scoped>
  .input_body {
    margin: 0 auto;
  }
  .serach_body {
    text-align: center;
    align-self: center;
    width: 300px;
    background-color: white;
    display: flex;
    direction: ltr;
    margin: 0 auto;
  }
  .top_header {
    background-color: #f0f2f5;
    width: 100%;
    height: 80px;
    direction: rtl;
    display: flex;
  }
  .caozuo {
    display: flex;
  }
  </style>