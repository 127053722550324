<template>
  <div>
    <a-descriptions title="小程序设置">
      <a-descriptions-item label="小程序ID">
        {{ setting_data.mini_app_id }}
      </a-descriptions-item>
      <a-descriptions-item label="小程序密钥">
        {{ setting_data.mini_app_key }}
      </a-descriptions-item>
      <a-descriptions-item label="商户号">
        {{ setting_data.mich_id }}
      </a-descriptions-item>
      <a-descriptions-item label="商户号密钥">
        {{ setting_data.mich_key }}
      </a-descriptions-item>
 
      <a-descriptions-item label="订阅模版ID">
        {{ setting_data.mini_moban_id }}
      </a-descriptions-item>
      <a-descriptions-item label="公众号ID">
        {{ setting_data.h5_app_id }}
      </a-descriptions-item>
      <a-descriptions-item label="公众号密钥">
        {{ setting_data.h5_app_key }}
      </a-descriptions-item>
 
    </a-descriptions>
    <div class="button_body">
      <a-button class="bianji_button" @click="show_edit_modal" type="primary"> 编辑 </a-button>
    </div>
    <a-modal
      title="编辑"
      :visible="edit_visible"
      @ok="edit_ok"
      @cancel="edit_cancel"
      :width="820"
    >
      <a-form-model :model="setting_data">
        <a-form-model-item label="小程序ID">
          <a-input style="width: 300px" v-model="setting_data.mini_app_id" />
        </a-form-model-item>
        <a-form-model-item label="小程序密钥">
          <a-input style="width: 300px" v-model="setting_data.mini_app_key" />
        </a-form-model-item>
        <a-form-model-item label="商户ID">
          <a-input style="width: 300px" v-model="setting_data.mich_id" />
        </a-form-model-item>
        <a-form-model-item label="商户密钥">
          <a-input style="width: 300px" v-model="setting_data.mich_key" />
        </a-form-model-item>
        <a-form-model-item label="模版ID">
          <a-input style="width: 300px" v-model="setting_data.mini_moban_id" />
        </a-form-model-item>
        <a-form-model-item label="公众号ID">
          <a-input style="width: 300px" v-model="setting_data.h5_app_id" />
        </a-form-model-item>
        <a-form-model-item label="公众号密钥">
          <a-input style="width: 300px" v-model="setting_data.h5_app_key" />
        </a-form-model-item>
        
      </a-form-model>
    </a-modal>
  </div>
</template>
<script>
import { getSetting, setSetting } from "@/api";
export default {
  data() {
    return {
      setting_data: {},
      edit_visible: false,
    };
  },
  methods: {
    show_edit_modal(){
        this.edit_visible=true
    },
    edit_ok() {
        setSetting(this.setting_data,this.setting_data.id).then(res=>{
            this.edit_visible=false
            getSetting().then((res) => {
      this.setting_data = res.data.data;
    });
        })
    },
    edit_cancel() {
        getSetting().then((res) => {
      this.setting_data = res.data.data;
      this.edit_visible=false
    });
    },
  },
  mounted() {
    getSetting().then((res) => {
      this.setting_data = res.data.data;
    });
  },
};
</script>
<style scoped>
.button_body {
  width: 100%;
  direction: rtl;
  margin-top: 30px;
  margin-right: 30px;
}
.bianji_button {
}
</style>