<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <a-layout id="components-layout-demo-custom-trigger">
    <a-layout-sider v-model="collapsed" :trigger="null" collapsible>
      <div class="logo">WARIS</div>
      <menu-dao></menu-dao>
    </a-layout-sider>
    <a-layout>
      <a-layout-header style="background: #fff; padding: 0">
        <a-icon
          class="trigger"
          :type="collapsed ? 'menu-unfold' : 'menu-fold'"
          @click="() => (collapsed = !collapsed)"
        />
        <user-avatar></user-avatar>
      </a-layout-header>
      <a-layout-content
        :style="{
          margin: '24px 16px',
          padding: '24px',
          background: '#fff',
          minHeight: '280px',
          
        }"
      >
        <router-view></router-view>
      </a-layout-content>
    </a-layout>
  </a-layout>
</template>
<script>
import MenuDao from "../components/Menu.vue";
import UserAvatar from '../components/user.vue'
export default {
  components: {
    MenuDao,
    UserAvatar
  },
  data() {
    return {
      collapsed: false,
    };
  },
};
</script>
<style>

.ant-layout-header{
    display: flex;
    justify-content: space-between;
}
.logo {
  color: white;
  text-align: center;
  line-height: 30px;
  font-size: 15px;
}
.ant-layout-has-sider {
  height: 100vh;
}
#components-layout-demo-custom-trigger .trigger {
  font-size: 18px;
  line-height: 64px;
  padding: 0 24px;
  cursor: pointer;
  transition: color 0.3s;
}

#components-layout-demo-custom-trigger .trigger:hover {
  color: #1890ff;
}

#components-layout-demo-custom-trigger .logo {
  height: 32px;
  background: rgba(255, 255, 255, 0.2);
  margin: 16px;
}
</style>