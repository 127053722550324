<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <div>
    <div class="top_header">
      <a-button class="add_button" @click="show_add_body" type="primary">
        添加
      </a-button>
      <div class="serach_body">
        <a-input-search
          class="input_body"
          placeholder="输入信息"
          enter-button="搜索"
          @search="onSearch"
        />
      </div>
    </div>
    <a-table
      :key="key"
      :columns="columns"
      :data-source="list"
      :pagination="false"
    >
      <div slot-scope="text, record" slot="poster">
        <img class="poster" :src="text" />
      </div>
      <div
        @click="show_kisim_table(record.id)"
        slot-scope="text, record"
        slot="components"
      >
        {{ text.length }}
      </div>
      <div class="caozuo" slot-scope="record" slot="caozuo">
        <a-button
          class="edit_button"
          @click="edit_button(record.id)"
          size="small"
          type="primary"
        >
          编辑
        </a-button>
        <a-popconfirm
          title="确定删除？"
          ok-text="Yes"
          cancel-text="No"
          @confirm="delete_ok(record.id)"
          @cancel="delete_cancel"
        >
          <a-button @click="delete_ok_ok(record.id)" size="small" type="danger">
            删除
          </a-button>
        </a-popconfirm>
      </div>

      <a-switch
        slot-scope="text, record"
        slot="is_vip"
        @change="status_change(record)"
        :defaultChecked="text == 1 ? true : false"
        checked-children="开"
        un-checked-children="关"
        default-checked
      />
      <a-switch
        slot-scope="text, record"
        slot="is_new"
        @change="is_new_status_change(record)"
        :defaultChecked="text == 1 ? true : false"
        checked-children="开"
        un-checked-children="关"
        default-checked
      />
      <a-switch
      slot-scope="text, record"
      slot="is_swiper"
      @change="swiper_status_change(record)"
      :defaultChecked="text == 1 ? true : false"
      checked-children="开"
      un-checked-children="关"
      default-checked
    />
      <a-switch
        slot-scope="text, record"
        slot="is_tor"
        @change="is_tor_status_change(record)"
        :defaultChecked="text == 1 ? true : false"
        checked-children="开"
        un-checked-children="关"
        default-checked
      />
      <a-switch
        slot-scope="text, record"
        slot="is_top"
        @change="top_status_change(record)"
        :defaultChecked="text == 1 ? true : false"
        checked-children="开"
        un-checked-children="关"
        default-checked
      />
      <a-switch
        slot-scope="text, record"
        slot="is_newing"
        @change="newing_status_change(record)"
        :defaultChecked="text == 1 ? true : false"
        checked-children="开"
        un-checked-children="关"
        default-checked
      />
    </a-table>
    <a-pagination @change="page_change" :total="total" show-less-items />

    <a-modal
      title="添加"
      :visible="add_visible"
      @ok="add_handleOk"
      @cancel="add_handleCancel"
      destroyOnClose
      :maskClosable="false"
      width="900px"
    >
      <a-form-model
        :model="add_data"
        :label-col="labelCol"
        :wrapper-col="wrapperCol"
      >
        <a-form-model-item required label="输入电影名字">
          <a-input v-model="add_data.name" />
        </a-form-model-item>
        <a-form-model-item required label="输入电影略图">
          <a-input v-model="add_data.poster" />

          <a-upload
            name="file"
            list-type="picture-card"
            class="avatar-uploader"
            :show-upload-list="false"
            :before-upload="beforeUpload"
            :customRequest="selfUpload"
            @change="handleChange"
          >
            <img style="width: 180px" v-if="add_data.poster" :src="add_data.poster" />
            <div v-else>
              <a-icon type="plus" />
              <div class="ant-upload-text">Upload</div>
            </div>
          </a-upload>
        </a-form-model-item>
        <a-form-model-item required label="输入电影横图">
          <a-input v-model="add_data.top_poster" />
          <a-upload
            name="file"
            list-type="picture-card"
            class="avatar-uploader"
            :show-upload-list="false"
            :before-upload="beforeUpload"
            :customRequest="top_selfUpload"
            @change="handleChange"
          >
            <img style="width: 180px" v-if="add_data.top_poster" :src="add_data.top_poster" />
            <div v-else>
              <a-icon type="plus" />
              <div class="ant-upload-text">Upload</div>
            </div>
          </a-upload>
        </a-form-model-item>
        <a-form-model-item required label="输入电影解释">
          <a-input type="textarea" v-model="add_data.text" />
        </a-form-model-item>
        <a-form-model-item required label="输入电影集数">
          <a-input v-model="add_data.component" style="width: 120px" />
        </a-form-model-item>
        <a-form-model-item required label="输入电影评分">
          <a-input v-model="add_data.score" style="width: 120px" />
        </a-form-model-item>
        <a-form-model-item required label="选择toplam">
          <a-select style="width: 200px" @change="toplam_handleChange">
            <a-select-option
              v-for="(item, index) in toplam_list"
              :key="item.id"
              :value="item.id"
            >
              {{ item.name }}
            </a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="选择电影演员">
          <a-select
            mode="tags"
            placeholder="选择演员"
            :default-value="[]"
            style="width: 200px"
            @change="stars_handleChange"
          >
            <a-select-option
              v-for="item in stars_data"
              :key="item.id.toString()"
            >
              <div class="stars_body">
                <img :src="item.poster" class="star_avatar" />
                <div class="star_name">{{ item.name }}</div>
              </div>
            </a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item required label="选择电影大分类">
          <a-select style="width: 120px" @change="type_handleChange">
            <a-select-option
              v-for="(item, index) in type_list"
              :key="index"
              :value="item.id"
            >
              {{ item.name }}
            </a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item required label="选择电影分类">
          <a-select style="width: 120px" @change="tur_handleChange">
            <a-select-option
              v-for="(item, index) in cate_data.tur"
              :key="index"
              :value="item.id"
            >
              {{ item.name }}
            </a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item required label="选择电影国家">
          <a-select style="width: 120px" @change="dolat_handleChange">
            <a-select-option
              v-for="(item, index) in cate_data.dolat"
              :key="index"
              :value="item.id"
            >
              {{ item.name }}
            </a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item required label="选择电影年份">
          <a-select style="width: 120px" @change="yil_handleChange">
            <a-select-option
              v-for="(item, index) in cate_data.yil"
              :key="index"
              :value="item.id"
            >
              {{ item.name }}
            </a-select-option>
          </a-select>
        </a-form-model-item>
      </a-form-model>
    </a-modal>

    <a-modal
      title="编辑"
      :visible="edit_visible"
      @ok="edit_handleOk"
      @cancel="edit_handleCancel"
      destroyOnClose
      :maskClosable="false"
      width="900px"
    >
      <a-form-model
        :model="item_data"
        :label-col="labelCol"
        :wrapper-col="wrapperCol"
      >
        <a-form-model-item required label="输入电影名字">
          <a-input v-model="item_data.name" />
        </a-form-model-item>
        <a-form-model-item required label="输入电影略图">
          <a-input v-model="item_data.poster" />
          <a-upload
            name="file"
            list-type="picture-card"
            class="avatar-uploader"
            :show-upload-list="false"
            :before-upload="beforeUpload"
            :customRequest="bian_selfUpload"
            @change="handleChange"
          >
            <img style="width: 180px" v-if="item_data.poster" :src="item_data.poster" />
            <div v-else>
              <a-icon type="plus" />
              <div class="ant-upload-text">Upload</div>
            </div>
          </a-upload>
        </a-form-model-item>
        <a-form-model-item required label="输入电影横图">
          <a-input v-model="item_data.top_poster" />
          <a-upload
            name="file"
            list-type="picture-card"
            class="avatar-uploader"
            :show-upload-list="false"
            :before-upload="beforeUpload"
            :customRequest="bian_top_selfUpload"
            @change="handleChange"
          >
            <img style="width: 180px" v-if="item_data.top_poster" :src="item_data.top_poster" />
            <div v-else>
              <a-icon type="plus" />
              <div class="ant-upload-text">Upload</div>
            </div>
          </a-upload>
        </a-form-model-item>
        <a-form-model-item required label="输入电影解释">
          <a-input type="textarea" v-model="item_data.text" />
        </a-form-model-item>
        <a-form-model-item required label="输入电影集数">
          <a-input v-model="item_data.component" style="width: 120px" />
        </a-form-model-item>
        <a-form-model-item required label="输入电影评分">
          <a-input v-model="item_data.score" style="width: 120px" />
        </a-form-model-item>
        <a-form-model-item required label="选择toplam">
          <a-select
            style="width: 200px"
            :default-value="item_data.toplam"
            @change="edit_toplam_handleChange"
          >
            <a-select-option
              v-for="(item, index) in toplam_list"
              :key="item.id"
              :value="item.id"
            >
              {{ item.name }}
            </a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="选择电影演员">
          <a-select
            mode="tags"
            placeholder="选择演员"
            :default-value="item_data.starts"
            style="width: 200px"
            @change="edit_stars_handleChange"
          >
            <a-select-option
              v-for="item in stars_data"
              :key="item.id.toString()"
            >
              <div class="stars_body">
                <img :src="item.poster" class="star_avatar" />
                <div class="star_name">{{ item.name }}</div>
              </div>
            </a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item required label="选择电影大分类">
          <a-select
            :default-value="item_data.type"
            style="width: 120px"
            @change="edit_type_handleChange"
          >
            <a-select-option
              v-for="(item, index) in type_list"
              :key="index"
              :value="item.id"
            >
              {{ item.name }}
            </a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item required label="选择电影分类">
          <a-select
            style="width: 120px"
            :default-value="item_data.tur_type"
            @change="edit_tur_handleChange"
          >
            <a-select-option
              v-for="(item, index) in cate_data.tur"
              :key="index"
              :value="item.id"
            >
              {{ item.name }}
            </a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item required label="选择电影国家">
          <a-select
            style="width: 120px"
            :default-value="item_data.dolat_type"
            @change="edit_dolat_handleChange"
          >
            <a-select-option
              v-for="(item, index) in cate_data.dolat"
              :key="index"
              :value="item.id"
            >
              {{ item.name }}
            </a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item required label="选择电影年份">
          <a-select
            style="width: 120px"
            :default-value="item_data.yil_type"
            @change="edit_yil_handleChange"
          >
            <a-select-option
              v-for="(item, index) in cate_data.yil"
              :key="index"
              :value="item.id"
            >
              {{ item.name }}
            </a-select-option>
          </a-select>
        </a-form-model-item>
      </a-form-model>
    </a-modal>

    <a-modal
      title="分集"
      :visible="kisim_visible"
      @ok="kisim_close"
      @cancel="kisim_close"
      destroyOnClose
      :maskClosable="false"
      width="1100px"
    >
      <a-button class="add_button" @click="show_com_add_body" type="primary">
        添加
      </a-button>
      <a-table
        :key="com_key"
        :columns="kisism_columns"
        :data-source="com_list"
        :pagination="false"
      >
        <div class="caozuo" slot-scope="record" slot="caozuo">
          <a-button
            class="edit_button"
            @click="com_edit_button(record.id)"
            size="small"
            type="primary"
          >
            编辑
          </a-button>
          <a-popconfirm
            title="确定删除？"
            ok-text="Yes"
            cancel-text="No"
            @confirm="com_delete_ok(record.id)"
            @cancel="com_delete_cancel"
          >
            <a-button
              @click="com_delete_ok_ok(record.id)"
              size="small"
              type="danger"
            >
              删除
            </a-button>
          </a-popconfirm>
        </div>

        <a-switch
          slot-scope="text, record"
          slot="is_vip"
          @change="com_status_change(record)"
          :defaultChecked="text == 1 ? true : false"
          checked-children="开"
          un-checked-children="关"
          default-checked
        />
      </a-table>
      <a-pagination
        @change="com_page_change"
        :total="com_total"
        show-less-items
      />
    </a-modal>

    <a-drawer
      title="添加分集"
      placement="right"
      closable
      :maskClosable="false"
      :visible="com_add_visible"
      :destroyOnClose="true"
      @close="com_add_onClose"
      width="800px"
    >
      <a-form-model
        :model="com_add_data"
        :label-col="labelCol"
        :wrapper-col="wrapperCol"
      >
        <a-form-model-item required label="输入序号">
          <a-input v-model="com_add_data.num" />
        </a-form-model-item>
        <a-form-model-item required label="输入连接">
          <a-input v-model="com_add_data.vid" />
        </a-form-model-item>
        <a-form-model-item required label="选择播放器">
          <a-select style="width: 120px" @change="com_type_handleChange">
            <a-select-option
              v-for="(item, index) in player"
              :key="index"
              :value="item"
            >
              {{ item }}
            </a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item required label="输入价格">
          <a-input v-model="com_add_data.price" />
        </a-form-model-item>
        <a-form-model-item required label="输入免费观看时间（分钟）">
          <a-input v-model="com_add_data.vip_time" />
        </a-form-model-item>
      </a-form-model>
      <a-button class="add_button" @click="add_com" type="primary">
        添加
      </a-button>
    </a-drawer>

    <a-drawer
      title="编辑分集"
      placement="right"
      closable
      :maskClosable="false"
      :visible="com_edit_visible"
      :destroyOnClose="true"
      @close="com_edit_onClose"
      width="800px"
    >
      <a-form-model
        :model="com_edit_data"
        :label-col="labelCol"
        :wrapper-col="wrapperCol"
      >
        <a-form-model-item required label="输入序号">
          <a-input v-model="com_edit_data.num" />
        </a-form-model-item>
        <a-form-model-item required label="输入连接">
          <a-input v-model="com_edit_data.vid" />
        </a-form-model-item>
        <a-form-model-item required label="选择播放器">
          <a-select style="width: 120px" @change="edit_com_type_handleChange">
            <a-select-option
              v-for="(item, index) in player"
              :key="index"
              :value="item"
            >
              {{ item }}
            </a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item required label="输入价格">
          <a-input v-model="com_edit_data.price" />
        </a-form-model-item>
        <a-form-model-item required label="输入免费观看时间（分钟）">
          <a-input v-model="com_edit_data.vip_time" />
        </a-form-model-item>
      </a-form-model>
      <a-button class="add_button" @click="edit_com" type="primary">
        确定
      </a-button>
    </a-drawer>
  </div>
</template>
  <script>
import {
  getFilmList,
  getFilmItem,
  addFilmItem,
  deleteFilmItem,
  updateFilmData,
  getTypeList,
  getCates,
  getStarsListData,
  getFilmComponents,
  setComItem,
  getComItem,
  updateComItem,
  deleteEComItem,
  getToplamList,
  imageUpload
} from "@/api";
const columns = [
  {
    dataIndex: "id",
    key: "id",
    align: "center",
    title: "id",
  },

  {
    title: "名称",
    align: "center",
    dataIndex: "name",
  },
  {
    title: "略图",
    align: "center",
    dataIndex: "poster",
    scopedSlots: { customRender: "poster" },
  },
  {
    title: "总集数",
    align: "center",
    dataIndex: "component",
  },
  {
    title: "集数",
    align: "center",
    dataIndex: "components",
    scopedSlots: { customRender: "components" },
  },
  {
    title: "大分类",
    align: "center",
    dataIndex: "type.name",
  },
  {
    title: "分类",
    align: "center",
    dataIndex: "tur.name",
  },
  {
    title: "点击量",
    align: "center",
    dataIndex: "look",
  },
  // {
  //   title: "国家",
  //   align: "center",
  //   dataIndex: "dolat.name",
  // },
  // {
  //   title: "年份",
  //   align: "center",
  //   dataIndex: "nian.name",
  // },
  // {
  //   title: "评分",
  //   align: "center",
  //   dataIndex: "score",
  // },
  {
    title: "是否完结",
    align: "center",
    dataIndex: "is_newing",
    scopedSlots: { customRender: "is_newing" },
  },
  {
    title: "是否最新",
    align: "center",
    dataIndex: "is_new",
    scopedSlots: { customRender: "is_new" },
  },
  {
    title: "是否抖音电影",
    align: "center",
    dataIndex: "is_tor",
    scopedSlots: { customRender: "is_tor" },
  },
  {
    title: "是否toplam",
    align: "center",
    dataIndex: "is_top",
    scopedSlots: { customRender: "is_top" },
  },
  {
    title: "是否swiper",
    align: "center",
    dataIndex: "is_swiper",
    scopedSlots: { customRender: "is_swiper" },
  },
  {
    title: "是否收费",
    align: "center",
    dataIndex: "is_vip",
    scopedSlots: { customRender: "is_vip" },
  },

  {
    title: "操作",
    align: "center",
    scopedSlots: { customRender: "caozuo" },
  },
];
const kisism_columns = [
  {
    dataIndex: "num",
    align: "center",
    title: "序号",
  },
  {
    title: "连接",
    dataIndex: "vid",
    align: "center",
  },
  {
    title: "是否会员观看",
    dataIndex: "is_vip",
    align: "center",
    scopedSlots: { customRender: "is_vip" },
  },

  {
    title: "价格",
    dataIndex: "price",
    align: "center",
  },
  {
    title: "试看时间",
    dataIndex: "vip_time",
    align: "center",
  },
  {
    title: "操作",
    align: "center",
    scopedSlots: { customRender: "caozuo" },
  },
];
function getBase64(img, callback) {
  const reader = new FileReader();
  reader.addEventListener("load", () => callback(reader.result));
  reader.readAsDataURL(img);
}

export default {
  data() {
    return {
      player:['vid','mp4','m3u8','could'],
      fileList: [],
      imageUrl: "",
      kisism_columns,
      labelCol: { span: 4 },
      wrapperCol: { span: 14 },
      columns,
      list: [],
      type_list: [],
      cate_data: {},
      page: 1,
      total: 0,
      key: 0,
      desc: "",
      add_visible: false,
      edit_visible: false,
      add_data: {
        name: "",
        type: "",
        poster: "",
        tur_type: "",
        dolat_type: "",
        yil_type: "",
        text: "",
        is_vip: 1,
        score: "",
        starts: "",
        status: 1,
        is_top: 0,
        top_poster: "",
        component: "",
        toplam: "",
        is_swiper:0
      },
      stars_data: [],
      item_data: {},
      kisim_visible: false,
      item_id: 0,
      com_page: 1,
      com_list: [],
      com_total: 0,
      com_key: 1,
      com_add_visible: false,
      com_add_data: {
        film_id: "",
        vid: "",
        price: "",
        vip_time: "",
        num: "",
        type:''
      },
      com_edit_data: {},
      com_edit_visible: false,
      toplam_list: [],
    };
  },
  methods: {
    edit_com_type_handleChange(e){
      console.log(e);
      this.com_edit_data.type=e
    },
    com_type_handleChange(e){
      console.log(e);
      this.com_add_data.type=e
    },
    bian_top_selfUpload({ action, file, onSuccess, onError, onProgress }){
      const formData = new FormData();
      let that = this;
      formData.append("file", file);
      imageUpload(formData).then(res=>{
        this.imageUrl=res.data.data.url
        this.item_data.top_poster=res.data.data.url
      })
    },
    bian_selfUpload({ action, file, onSuccess, onError, onProgress }){
      const formData = new FormData();
      let that = this;
      formData.append("file", file);
      imageUpload(formData).then(res=>{
        this.imageUrl=res.data.data.url
        this.item_data.poster=res.data.data.url
      })
    },
    selfUpload({ action, file, onSuccess, onError, onProgress }) {
      const formData = new FormData();
      let that = this;
      formData.append("file", file);
      imageUpload(formData).then(res=>{
        this.imageUrl=res.data.data.url
        this.add_data.poster=res.data.data.url
      })
    },
    top_selfUpload({ action, file, onSuccess, onError, onProgress }){
      const formData = new FormData();
      let that = this;
      formData.append("file", file);
      imageUpload(formData).then(res=>{
        this.imageUrl=res.data.data.url
        this.add_data.top_poster=res.data.data.url
      })
    },

    beforeUpload(file) {
      const isJpgOrPng =
        file.type === "image/jpeg" || file.type === "image/png";
      if (!isJpgOrPng) {
        this.$message.error("You can only upload JPG file!");
      }
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isLt2M) {
        this.$message.error("Image must smaller than 2MB!");
      }
      console.log(isJpgOrPng && isLt2M);
      return isJpgOrPng && isLt2M;
    },
    handleCancel() {
      this.previewVisible = false;
    },
    async handlePreview(file) {
      if (!file.url && !file.preview) {
        file.preview = await getBase64(file.originFileObj);
      }
      this.previewImage = file.url || file.preview;
      this.previewVisible = true;
    },
    handleChange({ fileList }) {
      this.fileList = fileList;
    },
    edit_toplam_handleChange(e) {
      console.log(e);
      this.item_data.toplam = e;
    },
    toplam_handleChange(e) {
      console.log(e);
      this.add_data.toplam = e;
    },
    com_status_change(e) {
      console.log(e);
      const param = {
        is_vip: e.is_vip == 1 ? 0 : 1,
      };
      updateComItem(param, e.id).then((res) => {
        console.log(res);
      });
    },
    com_delete_ok(id) {
      deleteEComItem(id).then((res) => {
        this.get_com_list(this.item_id);
      });
    },
    com_delete_ok_ok() {},
    edit_com() {
      updateComItem(this.com_edit_data, this.com_edit_data.id).then((res) => {
        this.com_edit_visible = false;
        this.get_com_list(this.item_id);
      });
    },
    com_edit_onClose() {
      this.com_edit_visible = false;
    },
    com_edit_button(id) {
      getComItem(id).then((res) => {
        console.log(res.data.data);
        this.com_edit_data = res.data.data;
        this.com_edit_visible = true;
      });
    },
    com_delete_cancel() {},
    add_com() {
      console.log("add");
      this.com_add_data.film_id = this.item_id;
      setComItem(this.com_add_data).then((res) => {
        console.log(res);
        this.com_add_visible = false;
        this.get_com_list(this.item_id);
      });
    },
    com_add_onClose() {
      this.com_add_visible = false;
    },
    show_com_add_body() {
      this.com_add_visible = true;
    },
    kisim_close() {
      this.kisim_visible = false;
      this.com_list=[]
      getFilmList(this.page,'').then((res) => {
      this.list = res.data.data.data;
      this.total = res.data.data.total;
      this.key = this.key + 1;
    });

    },
    get_com_list(id) {
      getFilmComponents(this.com_page, { id: id }).then((res) => {
        this.com_list = res.data.data.data;
        this.com_total = res.data.data.total;
        this.com_key++;
      });
    },
    show_kisim_table(id) {
      this.kisim_visible = true;
      this.item_id = id;
      this.get_com_list(id);
    },
    edit_handleCancel() {
      this.edit_visible = false;
      this.item_data = {};
    },
    edit_handleOk() {
      this.item_data.starts = this.item_data.starts.toString();
      updateFilmData(this.item_data, this.item_data.id).then((res) => {
        this.edit_visible = false;
        this.item_data = {};
        getFilmList(this.page,'').then((res) => {
          this.list = res.data.data.data;
          this.total = res.data.data.total;
          this.key = this.key + 1;
        });
      });
    },
    edit_button(id) {
      getFilmItem(id).then((res) => {
        this.item_data = res.data.data;
        this.edit_visible = true;
      });
    },
    yil_handleChange(e) {
      this.add_data.yil_type = e;
    },
    edit_yil_handleChange(e) {
      this.item_data.yil_type = e;
    },
    dolat_handleChange(e) {
      this.add_data.dolat_type = e;
    },
    edit_dolat_handleChange(e) {
      this.item_data.dolat_type = e;
    },
    tur_handleChange(e) {
      this.add_data.tur_type = e;
    },
    edit_tur_handleChange(e) {
      this.item_data.tur_type = e;
    },
    stars_handleChange(value) {
      this.add_data.starts = value.toString();
      console.log(`Selected: ${value}`);
    },
    edit_stars_handleChange(value) {
      this.item_data.starts = value.toString();
    },
    type_handleChange(e) {
      console.log(e);
      this.add_data.type = e;
    },
    edit_type_handleChange(e) {
      this.item_data.type = e;
    },
    show_add_body() {
      this.add_visible = true;
    },
    add_handleCancel() {
      this.add_visible = false;
    },
    add_handleOk() {
      addFilmItem(this.add_data).then((res) => {
        console.log(res);
        getFilmList(this.page,'').then((res) => {
          this.list = res.data.data.data;
          this.total = res.data.data.total;
          this.key = this.key + 1;
        });
        this.add_visible = false;
      });
    },
    delete_ok(id) {
      console.log(id);
      deleteFilmItem(id).then((res) => {
        getFilmList(this.page,'').then((res) => {
          this.list = res.data.data.data;
          this.total = res.data.data.total;
          this.key = this.key + 1;
        });
      });
    },
    delete_cancel(id) {},

    onSearch(value) {
      this.page = 1;

      getFilmList(this.page,value).then((res) => {
        this.list = res.data.data.data;
        this.total = res.data.data.total;
        this.key = this.key + 1;
      });
    },
    page_change(e) {
      this.page = e;
      getFilmList(this.page,'').then((res) => {
        this.list = res.data.data.data;
        this.total = res.data.data.total;
        this.key = this.key + 1;
      });
    },
    com_page_change(e) {
      this.com_page = e;
      getFilmComponents(this.com_page, { id: this.item_id }).then((res) => {
        this.com_list = res.data.data.data;
        this.com_total = res.data.data.total;
        this.com_key++;
      });
    },
    swiper_status_change(e){
      const param = {
        is_swiper: e.is_swiper == 1 ? 0 : 1,
      };
      updateFilmData(param, e.id).then((res) => {
        console.log(res);
      });
    },
    status_change(e) {
      const param = {
        is_vip: e.is_vip == 1 ? 0 : 1,
      };
      updateFilmData(param, e.id).then((res) => {
        console.log(res);
      });
    },
    is_new_status_change(e){
      const param = {
        is_new: e.is_new == 1 ? 0 : 1,
      };
      updateFilmData(param, e.id).then((res) => {
        console.log(res);
      });
    },
    is_tor_status_change(e){
      const param = {
        is_tor: e.is_tor == 1 ? 0 : 1,
      };
      updateFilmData(param, e.id).then((res) => {
        console.log(res);
      });
    },
    newing_status_change(e) {
      console.log(e);
      const param = {
        is_newing: e.is_newing == 1 ? 0 : 1,
      };
      updateFilmData(param, e.id).then((res) => {
        console.log(res);
      });
    },
    top_status_change(e) {
      const param = {
        is_top: e.is_top == 1 ? 0 : 1,
      };
      updateFilmData(param, e.id).then((res) => {
        console.log(res);
        getToplamList().then((res) => {
          this.toplam_list = res.data.data;
        });
      });
    },
  },
  mounted() {
    // this.apiUrl = this.global.apiUrl;

    getToplamList().then((res) => {
      this.toplam_list = res.data.data;
    });
    getFilmList(this.page,'').then((res) => {
      this.list = res.data.data.data;
      this.total = res.data.data.total;
      this.key = this.key + 1;
    });

    getTypeList().then((res) => {
      this.type_list = res.data.data;
    });
    getCates().then((res) => {
      this.cate_data = res.data.data;
    });
    getStarsListData().then((res) => {
      this.stars_data = res.data.data;
    });
  },
};
</script>
  <style scoped>
.ant-upload-select-picture-card i {
  font-size: 32px;
  color: #999;
}

.ant-upload-select-picture-card .ant-upload-text {
  margin-top: 8px;
  color: #666;
}
.kisim_top {
  width: 100px;
  height: 100px;
  background-color: blue;
}
.star_name {
  align-self: center;
  margin-left: 10px;
}
.stars_body {
  display: flex;
}
.star_avatar {
  width: 30px;
  height: 30px;
  border-radius: 100%;
}
.poster {
  width: 50px;
  height: 80px;
}
.add_button {
  align-self: center;
  margin-right: 30px;
}
.input_body {
  margin: 0 auto;
}
.serach_body {
  text-align: center;
  align-self: center;
  width: 300px;
  background-color: white;
  display: flex;
  direction: ltr;
  margin: 0 auto;
}
.top_header {
  background-color: #f0f2f5;
  width: 100%;
  height: 80px;
  direction: rtl;
  display: flex;
}
.caozuo {
  display: flex;
}
</style>