var render = function render(){var _vm=this,_c=_vm._self._c;return _c('a-layout',{attrs:{"id":"components-layout-demo-custom-trigger"}},[_c('a-layout-sider',{attrs:{"trigger":null,"collapsible":""},model:{value:(_vm.collapsed),callback:function ($$v) {_vm.collapsed=$$v},expression:"collapsed"}},[_c('div',{staticClass:"logo"},[_vm._v("WARIS")]),_c('menu-dao')],1),_c('a-layout',[_c('a-layout-header',{staticStyle:{"background":"#fff","padding":"0"}},[_c('a-icon',{staticClass:"trigger",attrs:{"type":_vm.collapsed ? 'menu-unfold' : 'menu-fold'},on:{"click":() => (_vm.collapsed = !_vm.collapsed)}}),_c('user-avatar')],1),_c('a-layout-content',{style:({
        margin: '24px 16px',
        padding: '24px',
        background: '#fff',
        minHeight: '280px',
        
      })},[_c('router-view')],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }