<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <div class="body_bac">
    <a-row  justify="space-around" type="flex"  align="top">
      <a-col :span="4">
        <a-card :title="'用户总数：'+dates.user.z" style="width: 300px">
          <p>今日增加：{{ dates.user.t }}</p>
          <p>昨日增加：{{ dates.user.y }}</p>
          
        </a-card>
      </a-col>
      <a-col :span="4">
        <a-card :title="'总收入：'+dates.price.z" style="width: 300px">
            <p>今日增加：{{ dates.price.t }}</p>
          <p>昨日增加：{{ dates.price.y }}</p>
        </a-card>
      </a-col>
      <a-col :span="4">
        <a-card :title="'总付费用户：'+dates.pay.z" style="width: 300px">
            <p>今日增加：{{ dates.pay.t }}</p>
          <p>昨日增加：{{ dates.pay.y }}</p>
        </a-card>
      </a-col>
      <a-col :span="4">
        <a-card :title="'总vip用户：'+dates.vip.z" style="width: 300px">
            <p>今日增加：{{ dates.vip.t }}</p>
          <p>昨日增加：{{ dates.vip.y }}</p>
        </a-card>
      </a-col>
    </a-row>
  </div>
</template>
<script>
import { getHomeData } from "@/api";
export default {
  data() {
    return {
        dates:{}
    };
  },
  mounted(){
    getHomeData().then(res=>{
        console.log(res.data.data);
        this.dates=res.data.data
    })
  }
};
</script>

<style>

</style>