<template>
    <div>
      <div class="top_header">
        <a-button class="add_button" @click="show_add_modal" type="primary"
          >添加</a-button
        >
      </div>
      <a-table
        :columns="columns"
        :data-source="list_data"
        :pagination="false"
        :key="key"
      >
      <div slot="image" slot-scope="text,record">
        <img class="image" :src="text" />
      </div>
        <div class="caozuo" slot="caozuo" slot-scope="record">
          <a-button
            @click="show_edit_modal(record.id)"
            size="small"
            type="primary"
            style="margin-right: 30px"
          >
            编辑
          </a-button>
          <a-popconfirm
            title="确定删除？"
            ok-text="Yes"
            cancel-text="No"
            @confirm="delet_book_item(record.id)"
            @cancel="delet_cancel"
          >
            <a-button
              size="small"
              type="danger"
            >
              删除
            </a-button>
          </a-popconfirm>
        </div>
      </a-table>
      <a-pagination
        @change="page_change"
        :total="total"
        show-less-items
      />
      <a-modal
        title="添加"
        :visible="add_visible"
        @ok="add_ok"
        @cancel="add_can"
        :width="820"
      >
        <a-form-model :model="add_from">
          <a-form-model-item label="演员名字">
            <a-input style="width: 300px" v-model="add_from.name" />
          </a-form-model-item>
          <a-form-model-item label="演员介绍">
            <a-input type="textarea" style="width: 300px" v-model="add_from.text" />
          </a-form-model-item>
          <a-form-model-item label="演员国家">
            <a-select style="width: 120px" @change="tur_handleChange">
              <a-select-option
                v-for="(item, index) in cate_data.dolat"
                :key="index"
                :value="item.id"
              >
                {{ item.name }}
              </a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item label="演员studio">
            <a-select style="width: 120px" @change="studio_tur_handleChange">
              <a-select-option
                v-for="(item, index) in studio"
                :key="index"
                :value="item.id"
              >
                {{ item.name }}
              </a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item label="演员照片">
            <a-upload
            name="file"
            list-type="picture-card"
            class="avatar-uploader"
            :show-upload-list="false"
            :before-upload="beforeUpload"
            :customRequest="selfUpload"
            @change="handleChange"
          >
            <img style="width: 180px" v-if="add_from.poster" :src="add_from.poster" />
            <div v-else>
              <a-icon type="plus" />
              <div class="ant-upload-text">Upload</div>
            </div>
          </a-upload>
          </a-form-model-item>
        </a-form-model>
      </a-modal>
      <a-modal
        title="编辑"
        :visible="edit_visible"
        @ok="edit_ok"
        @cancel="edit_can"
        :width="820"
      >
      <a-form-model :model="edit_from">
        <a-form-model-item label="演员名字">
          <a-input style="width: 300px" v-model="edit_from.name" />
        </a-form-model-item>
        <a-form-model-item label="演员介绍">
          <a-input type="textarea" style="width: 300px" v-model="edit_from.text" />
        </a-form-model-item>
        <a-form-model-item label="演员国家">
          <a-select  style="width: 120px" @change="edit_tur_handleChange">
            <a-select-option
              v-for="(item, index) in cate_data.dolat"
              :key="index"
              :value="item.id"
            >
              {{ item.name }}
            </a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="演员studio">
          <a-select style="width: 120px" @change="edit_studio_tur_handleChange">
            <a-select-option
              v-for="(item, index) in studio"
              :key="index"
              :value="item.id"
            >
              {{ item.name }}
            </a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="演员照片">
          <a-upload
          name="file"
          list-type="picture-card"
          class="avatar-uploader"
          :show-upload-list="false"
          :before-upload="beforeUpload"
          :customRequest="edit_selfUpload"
          @change="handleChange"
        >
          <img style="width: 180px" v-if="edit_from.poster" :src="edit_from.poster" />
          <div v-else>
            <a-icon type="plus" />
            <div class="ant-upload-text">Upload</div>
          </div>
        </a-upload>
        </a-form-model-item>
      </a-form-model>
      </a-modal>
    </div>
  </template>
  
  <script>
  
  import {
    getStarsList,
    getStarsItem,
    addStarsItem,
    deleteStarsItem,
    updateStarsData,
    getCates,
    imageUpload,
    getVipList,
    getVipItem,
    addVipItem,
    deleteVipItem,
    updateVipData,
  } from "@/api";
  function getBase64(img, callback) {
  const reader = new FileReader();
  reader.addEventListener("load", () => callback(reader.result));
  reader.readAsDataURL(img);
}
  const columns = [
    {
      dataIndex: "id",
      key: "id",
      align: "center",
      title: "id",
    },
    {
      title: "演员照片",
      dataIndex: "poster",
      align: "center",
      scopedSlots: { customRender: "image" },
    },
    {
      title: "演员名字",
      dataIndex: "name",
      align: "center",
    },
    {
      title: "国家",
      align: "center",
      dataIndex: "guojia",
    },
    // {
    //   title: "性别",
    //   align: "center",
    //   dataIndex: "sex",
    // },
    
  
    {
      title: "操作",
      align: "center",
      scopedSlots: { customRender: "caozuo" },
    },
  ];
  export default {
    data() {
      return {
        studio:[
          {
            id:1,
            name:'ھوللىۋود'
          },
          {
            id:2,
            name:'بوللىۋود'
          },
        ],
        columns,
        list_data: [],
        add_visible: false,
        add_from: {
          name: "",
          poster: "",
          sex:0,
          dolat:'',
          text:''
        },
        edit_from: {},
        edit_visible: false,
        page:1,
        cate_data:[],
        total:0,
        key:1
      };
    },
    mounted() {
      getCates().then((res) => {
      this.cate_data = res.data.data;
    });
        getStarsList(this.page).then((res) => {
        this.list_data = res.data.data.data;
        this.total=res.data.data.total
        this.key++
      });
    },
    methods: {
      page_change(e){
        this.page=e
        this.key++
        getStarsList(this.page).then((res) => {
        this.list_data = res.data.data.data;
        this.total=res.data.data.total
        
      });
      },
      edit_studio_tur_handleChange(e){
        this.edit_from.sex=e
      },
      studio_tur_handleChange(e){
        this.add_from.sex=e
      },
      edit_tur_handleChange(e){
        this.edit_from.dolat=e
      },
      tur_handleChange(e) {
      this.add_from.dolat = e;
    },
    tur_handleChange1(e) {
      this.edit_from.dolat = e;
    },
      bian_selfUpload({ action, file, onSuccess, onError, onProgress }) {
      const formData = new FormData();
      let that = this;
      formData.append("file", file);
      imageUpload(formData).then(res=>{
        
        this.edit_cate_from.poster=res.data.data.url
      })
    },
    edit_selfUpload({ action, file, onSuccess, onError, onProgress }){
      const formData = new FormData();
      let that = this;
      formData.append("file", file);
      imageUpload(formData).then(res=>{
        
        this.edit_from.poster=res.data.data.url
      })
    },
    selfUpload({ action, file, onSuccess, onError, onProgress }) {
      const formData = new FormData();
      let that = this;
      formData.append("file", file);
      imageUpload(formData).then(res=>{
        
        this.add_from.poster=res.data.data.url
      })
    },
    beforeUpload(file) {
      const isJpgOrPng =
        file.type === "image/jpeg" || file.type === "image/png";
      if (!isJpgOrPng) {
        this.$message.error("You can only upload JPG file!");
      }
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isLt2M) {
        this.$message.error("Image must smaller than 2MB!");
      }
      console.log(isJpgOrPng && isLt2M);
      return isJpgOrPng && isLt2M;
    },
    handleCancel() {
      this.previewVisible = false;
    },
    async handlePreview(file) {
      if (!file.url && !file.preview) {
        file.preview = await getBase64(file.originFileObj);
      }
      this.previewImage = file.url || file.preview;
      this.previewVisible = true;
    },
    handleChange({ fileList }) {
      this.fileList = fileList;
    },
      delet_book_item(id){
        this.key++
          console.log(id);
          deleteStarsItem(id).then(res=>{
              getStarsList(this.page).then((res) => {
        this.list_data = res.data.data.data;
        this.total=res.data.data.total
        
      });
          })
      },
      delet_cancel(){},
      show_edit_modal(id) {
        getStarsItem(id).then((res) => {
          this.edit_from = res.data.data;
          this.edit_visible = true;
        });
      },
      edit_can() {
        this.edit_visible = false;
        this.edit_from = {};
      },
      edit_ok() {
        this.key++
        updateStarsData(this.edit_from, this.edit_from.id).then((res) => {
          getStarsList(this.page).then((res) => {
            this.list_data = res.data.data;
            this.total=res.data.data.total
          });
          this.edit_visible=false
        });
      },
      add_can() {
        this.add_visible = false;
        this.add_from = {
          name: "",
          price: "",
        };
      },
      add_ok() {
        this.key++
        addStarsItem(this.add_from).then((res) => {
          this.add_visible=false
          getStarsList(this.page).then((res) => {
        this.list_data = res.data.data.data;
        this.total=res.data.data.total

      });
        });
      },
      show_add_modal() {
        this.add_visible = true;
      },
    },
  };
  </script>
  
  <style scoped>
  .add_button {
    align-self: center;
    margin-right: 50px;
  }
  .top_header {
    background-color: #f0f2f5;
    width: 100%;
    height: 80px;
    display: flex;
    direction: rtl;
  }
  .image{
    width: 60px;
    height: 90px;
    border-radius: 10px;
  }
  </style>